import React from "react";
import Helmet from "react-helmet";
import Transition from "../components/transition";
import Header from "../components/header";
import Footer from "../components/footer";
import "../styles/custom.scss";

const TemplateWrapper = ({ children, location }) => (
  <div className="body-layout">
    <Helmet title="Matt Williams">
      <script src="https://use.fontawesome.com/releases/v5.0.6/js/brands.js" />
      <script src="https://use.fontawesome.com/releases/v5.0.6/js/solid.js" />
      <script src="https://use.fontawesome.com/releases/v5.0.6/js/fontawesome.js" />
      <link
        href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,700"
        rel="stylesheet"
      />
    </Helmet>
    <Header />
    <Transition location={location}>{children}</Transition>
    <Footer />
  </div>
);

export default TemplateWrapper;
