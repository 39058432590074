/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";

const Index = () => (
  <div className="container d-flex flex-grow-1" style={{ marginTop: `-5rem` }}>
    <div className="row flex-column justify-content-center align-items-center">
      <Helmet title={"Matt Williams | Software Developer"} />
      <div className="col-12 col-md-11 col-lg-8 d-flex flex-column justify-content-center">
        <h1 className="display-1">
          Hi, I'm Matt{" "}
          <span role="img" aria-label="wave">
            👋
          </span>
        </h1>
        <div className="lead mt-3 mb-4">
          <span>
            I'm a software developer based in Grand Rapids, Michigan.{" "}
          </span>
          <span className="d-inline-block">
            Web applications are my specialty. Want to chat?{" "}
            <Link to="/contact/">Get in touch!</Link>
          </span>
        </div>
        <div className="d-flex justify-content-between social-links">
          <div className="">
            <a
              href="https://twitter.com/mwilliamsdev"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter" />
            </a>
          </div>
          <div className="">
            <a
              href="https://github.com/mattwills23"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-github" />
            </a>
          </div>
          <div className="">
            <a href="mailto:matt@mattwilliams.io">
              <i className="fas fa-envelope" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Index;
