import React from "react";
import { Link } from "gatsby";
import picture from "../images/profile-pic.jpeg";
export default class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.closeNavbar = this.closeNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  closeNavbar() {
    if (this.state.collapsed === false) {
      this.toggleNavbar();
    }
  }

  render() {
    return (
      <header className="mb-5">
        <nav className="navbar navbar-expand-md navbar-light pt-4">
          <div className="container">
            <Link className="navbar-brand" activeClassName="active" to="/">
              <img
                src={picture}
                width="40px"
                height="40px"
                className="rounded-circle mr-3"
                alt="profile"
              />
              Matt Williams
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              onClick={this.toggleNavbar}
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className={
                "collapse navbar-collapse " +
                (!this.state.collapsed ? "show" : "")
              }
              id="navbarCollapse"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClassName="active"
                    to="/about"
                    onClick={this.closeNavbar}
                  >
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    activeClassName="active"
                    href="/img/chemex.jpg"
                    download
                    onClick={this.closeNavbar}
                  >
                    Resume
                  </a>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClassName="active"
                    to="/contact"
                    onClick={this.closeNavbar}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}
