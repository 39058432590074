import React from "react";
import Helmet from "react-helmet";
import desk from "../images/home-office.jpeg";
import profile from "../images/with-jimmy.jpeg";
import collage from "../images/collage.png";

const About = () => (
  <div className="container">
    <Helmet title={"Matt Williams | About Me"} />
    <div className="row justify-content-center align-items-center ">
      <div className="col-md-8">
        <h2 className="mb-5">
          <span>About Me</span>
        </h2>

        <img
          alt="profile"
          className="float-right w-30 p-4 mr-3"
          src={profile}
        />
        <p>
          I have almost 5 years of professional experience developing custom
          software using modern web technologies. I've worked with and led agile
          teams to build products and tools for clients that range from small
          startups to multi-national corporations.
        </p>
        <p className="pb-lg-5">
          A true "full-stack" developer, I have knowledge and experience in all
          aspects of application development. I can go from building a complex
          user interface one week to automating the deployment of cloud
          infrastructure the next. Although I'm comfortable wearing many hats, I
          primarily enjoy working on the backend and building solutions that
          scale.
        </p>
        <div className="float-lg-right">
          <h6 className="mt-4">Types of projects I've worked on</h6>
          <ul>
            <li>S.a.a.S products</li>
            <li>Ecommerce platforms</li>
            <li>Enterprise tools</li>
            <li>iOS applications</li>
          </ul>
        </div>
        <div className="w-50 pr-3">
          <img alt="desk" className="w-100" src={desk} />
        </div>
        <p className="py-lg-5 py-md-3">
          When people ask where I'm from I say Chicago, but growing up I moved
          all over the United States and even spent a few years overseas in
          Singapore. I currently live in Grand Rapids, Michigan with my
          girlfriend Tess and our golden retriever Jimmy. When I'm not in front
          of a computer screen, I'm usually mountain biking or relaxing on one
          of Lake Michigan's amazing beaches.
        </p>
        <div className="w-50 pr-3 float-lg-right">
          <img alt="collage" className="w-100" src={collage} />
        </div>
        <div className="">
          <h6 className="mt-4">A few of my favorite things</h6>
          <ul>
            <li>Chicago sports</li>
            <li>Spicy margaritas</li>
            <li>Marco Island, FL</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);

export default About;
